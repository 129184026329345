<template>
  <base-layout-2
    :noPageHeader="false"
    :toolbarClass="'class-3'"
    :headerClass="'fixed-home'"
    :contentColor="'light'"
    :backButtonClass="'btn-1'"
    :pageDefaultBackLink="'/home'"
  >
    <template v-slot:ion-content>
      <div class="wrap">
        <div
          class="image"
          @click="showText = !showText"
          id="general_notification_image_wrap"
          v-if="notificationItem?.general_notification_image"
        >
          <img
            :src="`${server}/storage/notification_images/${notificationItem.general_notification_image}`"
            alt=""
            id="general_notification_image"
          />
        </div>

        <transition name="fade">
          <div
            :class="{
              text: true,
              'no-image': !notificationItem?.general_notification_image,
            }"
            v-show="showText"
            v-if="notificationItem?.general_notification_text"
          >
            <p>{{ notificationItem.general_notification_text }}</p>
            <!-- <p>{{ test_text }}</p> -->
          </div>
        </transition>
      </div>
    </template>
  </base-layout-2>
</template>


<script>
import BaseLayout2 from "../layouts/Base2";
import Zoom from "zoom-it";

export default {
  inject: ["store"],
  data() {
    return {
      showText: true,
      test_text:
        "#Bonk\n\nDEC 15th was indeed the day the fall started, 66% drop in 3 weeks \ud83d\udc4d\n\nlosing 0.000090 low will activate another sell wave but before that we need to see if the current area can hold or not. #Bonk\n\nDEC 15th was indeed the day the fall started, 66% drop in 3 weeks \ud83d\udc4d\n\nlosing 0.000090 low will activate another sell wave but before that we need to see if the current area can hold or not.",
    };
  },
  components: {
    "base-layout-2": BaseLayout2,
  },

  methods: {},

  computed: {
    notificationItem() {
      return this.store.state.generalNotificationInView;
    },

    server() {
      return this.store.server;
    },
  },

  async ionViewDidEnter() {
    // let self = this;
    if (this.notificationItem?.general_notification_image) {
      let el = document.getElementById("general_notification_image");
      let zm = new Zoom.Zoom(el, {
        rotate: false,
      });
      zm;
    }

    if (!this.store.state.appReady) {
      this.emitter.emit("appSignedIn");
    }
  },
};
</script>


<style scoped lang="scss">
.page-container {
  border-top-left-radius: 40px;
  background: var(--ion-color-light);
  overflow: auto;
  padding-top: 50px;
}

.wrap {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #000;
  position: relative;

  .image {
    position: relative;
    width: 100%;
    overflow: scroll;

    img {
      max-width: unset;
      width: 100%;
      position: relative;
      height: 100%;
    }
  }

  .text {
    color: #fff;
    white-space: pre-wrap;
    text-align: left;
    padding: 0 20px;
    position: absolute;
    bottom: 0px;
    height: 200px;
    overflow: auto;
    background: rgba(0, 0, 0, 0.5);
    width: 100%;

    &.no-image {
      height: 80%;
    }
  }

  h2 {
    font-weight: bold;
    text-align: center;
  }
}

#getItOnPlaystore {
  width: 200px;

  img {
    width: 100%;
  }
}

.exit {
  display: block;
  margin-top: 20px;
  font-weight: 800;
  color: var(--ion-color-primary);
  text-decoration: none;
}
</style>